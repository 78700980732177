<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" :disabled="disabled" v-on="on">
        <v-list-item-title>Ver oferta no varejista</v-list-item-title>
      </v-list-item>
    </template>

    <v-card style="overflow-x:hidden">
      <v-card-title>
        Saindo do Backoffice da Indústria
      </v-card-title>

      <v-card-text>
        <span>Para visualizar essa oferta corretamente é necessário que você esteja logado na plataforma do seguinte varejista:</span>
        <div class="d-flex justify-center mt-4">
          <strong>{{ item.id_external }} - {{ item.db_name }}</strong>
        </div>
      </v-card-text>

      <mf-action-buttons
        class="mb-3 mr-3"
        :primary-button="{
          text: 'Continuar',
          action: goToRetailerPlatform,
          isVisible: true
        }"
        :cancel-button="{
          text: 'Fechar',
          action: () => (dialog = false),
          isVisible: true
        }"
      />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'GoToRetailerDialog',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    dialog: false
  }),
  methods: {
    goToRetailerPlatform() {
      this.dialog = false
      return window.open(`https://web.mercafacil.com/#/industria/ofertas-trade/visualizar/${this.item.offers_schedule_id}`, '_blank')
    }
  }
}
</script>
