var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":_vm.disabled}},'v-list-item',attrs,false),on),[_c('v-list-item-title',[_vm._v("Ver oferta no varejista")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticStyle:{"overflow-x":"hidden"}},[_c('v-card-title',[_vm._v(" Saindo do Backoffice da Indústria ")]),_c('v-card-text',[_c('span',[_vm._v("Para visualizar essa oferta corretamente é necessário que você esteja logado na plataforma do seguinte varejista:")]),_c('div',{staticClass:"d-flex justify-center mt-4"},[_c('strong',[_vm._v(_vm._s(_vm.item.id_external)+" - "+_vm._s(_vm.item.db_name))])])]),_c('mf-action-buttons',{staticClass:"mb-3 mr-3",attrs:{"primary-button":{
        text: 'Continuar',
        action: _vm.goToRetailerPlatform,
        isVisible: true
      },"cancel-button":{
        text: 'Fechar',
        action: function () { return (_vm.dialog = false); },
        isVisible: true
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }